import { Fragment, useState, useEffect, ReactFragment } from "react";
import { Button, Divider, Spin } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { 
    getPopularInterests, 
    getPopularLocations, 
    getPopularClubs 
} from "../../../../actions/pivotAdmin";
import SortIcon from '../../../../assets/images/icons/pivot-admin/sort.svg'
import Event from "./components/Event";

const EventsBreakdown=(props:{
    dates: any,
    getPopularInterests: Function, 
    getPopularLocations: Function, 
    getPopularClubs: Function, 
})=>{
    const {
        dates,
        getPopularInterests, 
        getPopularLocations, 
        getPopularClubs 
    } = props;
    const [ selectedTab, setSelectedTab ] = useState(0);
    const [ showMore, setShowMore ] = useState(false);

    const [popInterestsData, setPopInterestsData] = useState([]);
    const [popLocationsData, setPopLocationsData] = useState([]);
    const [popClubsData, setPopClubsData] = useState([]);
    const [ isFetching, setIsFetching ] = useState(false);
    const [ isHighest, setIsHighest ] = useState(true);

    const fetchBreakdown = async() =>{
        setIsFetching(true);
        const defaultFrom = moment().startOf("month").utc().toISOString();
        const defaultTo = moment().endOf("month").utc().toISOString();
        const resInt = await getPopularInterests({
            from: dates[0]?dates[0]:defaultFrom,
            to: dates[1]?dates[1]:defaultTo,
            limit: 5,
        });
        if(resInt.status===200){
            let alphabetical = resInt.data?.sort((a:any, b:any) => a.name.localeCompare(b.name))
            setPopInterestsData(alphabetical?.sort((a: any, b: any) => (b?.noOfEvents) - (a?.noOfEvents)));
        }
        const resLoc = await getPopularLocations({
            from: dates[0]?dates[0]:defaultFrom,
            to: dates[1]?dates[1]:defaultTo,
            limit: 5,
        });
        if(resLoc.status===200){
            let alphabetical2 = resLoc.data?.sort((a:any, b:any) => a.name.localeCompare(b.name))
            setPopLocationsData(alphabetical2?.sort((a: any, b: any) => (b?.noOfEvents) - (a?.noOfEvents)));
        }
        const resCLub = await getPopularClubs({
            from: dates[0]?dates[0]:defaultFrom,
            to: dates[1]?dates[1]:defaultTo,
            limit: 5,
        });
        if(resCLub.status===200){
            let alphabetical3 = resCLub.data?.sort((a:any, b:any) => a.name.localeCompare(b.name))
            setPopClubsData(alphabetical3?.sort((a: any, b: any) => (b?.noOfEvents) - (a?.noOfEvents)));
        }
        setIsFetching(false);
    }

    const sortEvents = () =>{
        setIsHighest(!isHighest);
        setPopInterestsData(popInterestsData?.reverse());
        setPopClubsData(popClubsData?.reverse());
        setPopLocationsData(popLocationsData?.reverse());
    }

    useEffect(() => {
        fetchBreakdown();
    }, [dates]);

    return(
        <div className="component-container" style={{height:'45vh'}}>
            <div className="header">
                <h1 className="title">
                    EVENTS BREAKDOWN
                </h1>
                <div id="events-breakdown-spin">
                    <Spin className="spin-loading" spinning={isFetching} />
                </div>
            </div>
            <div className="tab-container">
                <div className="group">
                <Button className={selectedTab===0?"tab-selected":"tab"} onClick={()=>setSelectedTab(0)}>Interest</Button>
                <Button className={selectedTab===1?"tab-selected":"tab"} onClick={()=>setSelectedTab(1)}>Clubs</Button>
                <Button className={selectedTab===2?"tab-selected":"tab"} onClick={()=>setSelectedTab(2)}>Location</Button>
                </div>
                <Button className={"tab"} onClick={sortEvents}><img src={SortIcon} style={{marginRight:'5px', transform:`scaleY(${isHighest?'1':'-1'})`}}/>{isHighest?"Highest":"Lowest"}</Button>
            </div>
            <Divider className="divider"/>
            <div className="breakdown">
                {selectedTab===0 && 
                    (popInterestsData.length>0?(
                        <Event data={popInterestsData.slice(0,showMore?popInterestsData.length:5)}/>
                    ):(
                        <p>No Data</p>
                    ))
                }
                {selectedTab===1 && 
                    (popClubsData.length>0?(
                        <Event data={popClubsData.slice(0,showMore?popClubsData.length:5)}/>
                    ):(
                        <p>No Data</p>
                    ))
                }
                {selectedTab===2 && 
                    (popLocationsData.length>0?(
                        <Event data={popLocationsData.slice(0,showMore?popLocationsData.length:5)}/>
                    ):(
                        <p>No Data</p>
                    ))
                }
            </div>
            {/* {
                (selectedTab===0 && popInterestsData.length>5)||(selectedTab===1 && popClubsData.length>5)||(selectedTab===2 && popLocationsData.length>5) &&
                <Button onClick={()=>setShowMore(!showMore)} type="text" className="text-btn">Show More</Button>
            } */}
            {/* <div className="construction">
                Oops! This feature is under construction.
            </div> */}
        </div>
    )
}

const mapStateToProps = (state: {
    auth: any;
    userIsBack: boolean;
    googleAccessToken: string;
  }) => ({
    userIsBack: state.userIsBack,
    googleAccessToken: state.auth?.googleAccessToken,
  });
export default connect(mapStateToProps, {
    getPopularInterests, 
    getPopularLocations, 
    getPopularClubs 
})(EventsBreakdown);