import { Route, Routes } from "react-router-dom";
import PrivacyPage from "../privacy/PrivacyPage";
import Profile from "../profile/Profile";
import CalendarPage from "../calendar/CalendarPage";
import ContactPage from "../social/ContactPage";
const MemberRoute = () => {
  return (
    <Routes>
      {/* <Route index={true} element={<Dashboard />} /> */}
      <Route path="/settings" element={<Profile />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/calendar" element={<CalendarPage />} />
      <Route path="/contacts/friend-requests" element={<ContactPage />} />
      <Route path="/contacts/sent-friend-requests" element={<ContactPage />} />
      <Route path="/contacts/friends" element={<ContactPage />} />
      <Route path="/contacts/blocklist" element={<ContactPage />} />
    </Routes>
  );
};

export default MemberRoute;
