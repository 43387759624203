import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../assets/scss/components/breezecalendar.scss";
import { Fragment, useState, useEffect, memo, SetStateAction, useMemo, useCallback } from "react";
import ModalEventView from "./ModalEventView";
import ModalEventList from "./ModalEventList";
import WeekDaySelector from "../member-app/calendar/WeekDaySelector";

import {
  CalendarToolBar,
  DayHeader,
  WeekHeader,
  EventComponentMonth,
  EventComponent,
  CustomEventWrapper,
  EventComponentDay,
  slotPropGetter,
  TimeGutterHeader,
} from "./CalendarComponents";
import { head } from "lodash";

const mLocalizer = momentLocalizer(moment);

interface IBreezeCalendar {
  eventsToday?: any;
  upcomingEvents?: any;
  eventsFuture?: any;
  handleCalendarFilterDateChange?: any;
  refresh?: any;
  eventTags?: any; 
  recommendedEvents?: any; 
  selectedTime?: any; 
  selectedInterest?: any; 
  googleEvents?: any; 
  isLoading?: any;
  member?: any;
  updateProfile: Function;
}
export const BreezeCalendar: React.FC<IBreezeCalendar> = ({ eventsFuture,
  handleCalendarFilterDateChange,
  refresh,
  eventTags,
  recommendedEvents,
  selectedTime,
  selectedInterest,
  googleEvents,
  isLoading,
  member,
  updateProfile,
}) => {
  const [eventData, setEventData] = useState<any>();
  const [isVisible, setIsVisible] = useState(false);
  const [fromMobile, setFromMobile] = useState(false);
  const [widthSize, setWidthSize] = useState<number>(window.innerWidth);
  const [eventListIsVisible, setEventListIsVisible] = useState(false);
  const [eventItems, setEventItems] = useState<any>([]);
  const [allEventItems, setAllEventItems]: any = useState([]);
  const [allConflictIds, setAllConflictIds]: any = useState([]);
  const [calView, setCalView] = useState("day");
  const [view, setView] = useState<any>(Views.DAY);
  // const [view, setView] = useState<any>(window.innerWidth<992?Views.DAY:Views.WEEK);
  // const [calView, setCalView] = useState(window.innerWidth<992?"day":"week");
  // const [view, setView] = useState<any>(window.innerWidth<992?Views.MONTH:Views.WEEK);
  const [overlappingEvents, setOverlappingEvents] = useState<any>([]);
  const [allDayEvents, setAllDayEvents] = useState<any>([]);
  const [dateFilter, setDateFilter] = useState(moment().toISOString());
  const [expandAllDay, setExpandAllDay] = useState(false);
  const [allDayCount, setAllDayCount] = useState({'total':0,'additional':0});

  const sortByInterest = (overlaps: any[]) => {
    if (!selectedInterest || selectedInterest.length === 0) return;

    overlaps = overlaps.map((e) => {
      if (!e.conflicts || e.conflicts.length === 0) return e;

      const tmp = [
        { name: e.eventTitle, id: e.eventId, interest: e.eventInterest },
        ...e.conflicts,
      ];

      const grouped = selectedInterest.map((f: any) => {
        return tmp.filter((g) => g.interest === f.name);
      });
      grouped.push(
        tmp.filter(
          (g) => !selectedInterest.map((h: any) => h.name).includes(g.interest)
        )
      );
      let sorted: any = [];
      let i = 0;
      while (grouped.some((f: any) => f[i])) {
        grouped.filter((f: any) => f[i]).forEach((f: any) => sorted.push(f[i]));
        i++;
      }

      const first = sorted.shift();

      e.eventTitle = first.name;
      e.eventId = first.id;
      e.eventInterest = first.interest;
      e.conflicts = sorted;
    });
  };

  const displayEventData = (event: any) => {
    setEventData(event);
    if (event.conflicts !== undefined) {
      setEventListIsVisible(true);
    } else if (event.classes !== undefined) {
      setEventListIsVisible(true);
    } else {
      setIsVisible(true);
    }
  };

  const onNavigate = (data: moment.MomentInput, view: SetStateAction<string>) => {
    setCalView(view);
    setDateFilter(moment(data, "YYYY MM DD").toISOString());
  };

  const eventPropGetterDefault = useCallback(
    (event: any, start: any, end: any, isSelected: any) => {
      let eventProps: any = {};
      eventProps.style = {
        color: 'transparent',
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        filter: 'none',
        margin: 0,
        paddingLeft: "2px",
        paddingRight: "2px",
      };

      return eventProps;
    },
    []
  );

  const CountAllDay = (events:any)=>{
    if(calView==='week'){
      let from = moment(dateFilter).startOf('week');
      let to = moment(dateFilter).endOf('week');
      let eventItems = (events.filter((e:any)=>moment(e.start).isBetween(from,to)||moment(e.end).isBetween(from,to)));
      let maxCount = 0;
      let rowCount = 0;
      for (let i = 0; i < eventItems.length; i++) {
        for (let j = i; j < eventItems.length; j++) {
          if(j===i) continue;
          if((moment(eventItems[i].start).isBefore(moment(eventItems[j].end))) && (moment(eventItems[i].end).isAfter(moment(eventItems[j].start)))){
            //overlaps
            rowCount++;
          }else{
            i=j-1;
          }
        }
        if(rowCount>maxCount){
          maxCount = rowCount
        }
        rowCount=0;
      }
      setAllDayCount({'total':eventItems.length,'additional':(maxCount)});
    }else if(calView==='day'){
      let day = moment(dateFilter).set({minute:0}).format('DD mm');
      let eventItems = (events.filter((e:any)=>moment(e.start).format('DD mm')<=day && moment(e.end).add(1,'minute').format('DD mm')>=day && (moment(e.end).format('DD mm')===day?(moment(e.end).format('HH mm')>'00 00'):true)));
      setAllDayCount({'total':eventItems.length,'additional':(eventItems.length -2)});
    }
  }

  const GetMonthSummary = (data: any) => {
    // setEventItems([]);
    let classCount = 0;
    let addedCount = 0;
    let primetimeCount = 0;
    let classEvent;
    let addedEvent;
    let yaleEvent;
    let summary = [];
    let sorted = data.sort((a: any, b: any) => Date.parse(a.start) - Date.parse(b.start)); //sorts events by date
    let lastDay = moment(dateFilter).endOf('month').format('MM DD');

    for (let i = 0; i < sorted?.length; i++) {
      //current day
      let currentDate = moment(sorted[i].start).format('MM DD');
      let calendarIds = [];
      let addedEventIds = [];
      let yaleIds = [];

      if(i>0 && currentDate === moment(sorted[i-1].start).format('MM DD')){ //checks if day repeats before last day
        break;
      }
      for (let j = i; j < sorted?.length; j++) {
        let jDate = moment(sorted[j].start).format('MM DD');
        if (currentDate!=jDate) {   //sets head to first event of next day
          i = j - 1;
          break;
        }
        if (sorted[j]?.isAddedEvent === true) {   //Added Event
          addedCount++;
          addedEventIds.push(sorted[j].eventId)
        } else if (sorted[j]?.isGoogleEvent === true) {
          //Calendar Event
          if (sorted[j].location === "breeze.school.event.preferred") {
            //fix count by ignoring redundant event
            continue;
          }
          classCount++;
          calendarIds.push(sorted[j].eventId)
        } else {  //Breeze Event
          primetimeCount++;
          yaleIds.push(sorted[j].eventId);
        }
        
      }
      classEvent = {
        title: `${classCount} Calendar Event/s`,
        eventId: null,
        allDay: false,
        start: new Date(sorted[i]?.start),
        end: new Date(moment(sorted[i]?.start).set("hour", 23).toISOString()),
        description: "calendar event count",
        location: null,
        memberGroup: null,
        organizer: null,
        isSchoolWide: false,
        isClass: true,
        isGoogleEvent: true,
        userInvite: null,
        count: classCount,
        classes: calendarIds,
      }
      addedEvent = {
        title: `${addedCount} Added Event/s`,
        eventId: null,
        allDay: false,
        start: new Date(sorted[i]?.start),
        end: new Date(moment(sorted[i]?.start).set("hour", 23).toISOString()),
        description: "added event count",
        location: null,
        memberGroup: null,
        organizer: null,
        isSchoolWide: false,
        isClass: true,
        isGoogleEvent: true,
        userInvite: null,
        count: addedCount,
        classes: addedEventIds,
        isAddedEvent: true,
      }
      yaleEvent = {
        title: `${primetimeCount} Breeze Event/s`,
        eventId: null,
        allDay: false,
        start: new Date(sorted[i]?.start),
        end: new Date(moment(sorted[i]?.start).set("hour", 23).toISOString()),
        description: "breeze event count",
        location: null,
        memberGroup: null,
        organizer: null,
        isSchoolWide: false,
        isClass: false,
        isGoogleEvent: false,
        userInvite: null,
        count: primetimeCount,
        classes: yaleIds,
      };
      if (primetimeCount > 0) {
        summary.push(yaleEvent);
      }
      if (addedCount > 0) {
        summary.push(addedEvent);
      }
      if (classCount > 0) {
        summary.push(classEvent);
      }
      classCount = 0;
      addedCount = 0;
      primetimeCount = 0;
      calendarIds = [];
      addedEventIds = [];
      yaleIds = [];

      setEventItems(summary);

      if(currentDate===lastDay){  //end after last day
        break;
      }
    }
  };

  const GetWeekDaySummary = (allEvents: any, overlaps: any) => {
    let conflicts = overlaps.filter((o: any) => o.conflicts !== null);
    let xconflicts = overlaps.filter((o: any) => o.conflicts === null);
    let conflictsIDs = conflicts.map((c: any) => c.eventId);
    let noConflicts = allEvents
      .filter((o: any) => !conflictsIDs.includes(o.eventId))
      .map((o: any) => o.eventId);
    let allDayEvents: any = [];
    let summary: any = [];
    for (let k = 0; k < conflicts.length; k++) {
      let eventIDs = [
        conflicts[k].eventId,
        ...conflicts[k]?.conflicts.map((cs: any, i: any) => cs.id),
      ];

      if(conflicts[k].eventIsAdded && (calView==="day" || (calView==="week" && widthSize<992))){  //added events with conflict
        let head = (allEvents?.filter((ev:any)=>conflicts[k].eventId === ev.eventId)[0]);
        summary.push({  //push head
          ... head,
          start: new Date(moment(head.start).set("minute", 0).set("second", 1).toISOString()),
          end: new Date(moment(head.start).add(1, "hour").set("minute", 0).subtract(1,"second").toISOString()),
          actualStart: head.start,
          actualEnd: head.end,
        })
        if(widthSize<677){
          let addedConflictIDs = [...conflicts[k]?.conflicts.map((cs: any, i: any) => cs.id).slice(0,)];
          let headEvent = (allEvents?.filter((ev:any)=>conflicts[k]?.conflicts[0].id === ev.eventId))[0];
          summary.push({
            title: headEvent.title,
            conflicts: addedConflictIDs,
            eventId: null,
            allDay: false,
            start: new Date(moment(headEvent.start).set("minute", 0).set('second',1).toISOString()),
            end: new Date(moment(headEvent.start).add(1, "hour").set("minute", 0).subtract(1,"second").toISOString()),
            description: "conflict summary for slot",
            location: null,
            memberGroup: null,
            organizer: null,
            isSchoolWide: false,
            isClass: false,
            isGoogleEvent: true,
            isAddedEvent: true,
            userInvite: null,
          })
        }else{
            if(conflicts[k]?.conflicts?.length>2){  //for the +n more   more than 3 conflicts
              let event = (allEvents?.filter((ev:any)=>conflicts[k]?.conflicts[0].id === ev.eventId))[0];
              summary.push({
              ... event,
              start: new Date(moment(event.start).set("minute", 0).set("second", 1).toISOString()),
              end: new Date(moment(event.start).add(1, "hour").set("minute", 0).subtract(1,"second").toISOString()),
              actualStart: event.start,
              actualEnd: event.end,
            });
            let addedConflictIDs = [...conflicts[k]?.conflicts.map((cs: any, i: any) => cs.id).slice(1,)];
            let headEvent = (allEvents?.filter((ev:any)=>conflicts[k]?.conflicts[1].id === ev.eventId))[0];

            summary.push({
              title: headEvent.title,
              conflicts: addedConflictIDs,
              eventId: null,
              allDay: false,
              start: new Date(moment(headEvent.start).set("minute", 0).set('second',1).toISOString()),
              end: new Date(moment(headEvent.start).add(1, "hour").set("minute", 0).subtract(1,"second").toISOString()),
              description: "conflict summary for slot",
              location: null,
              memberGroup: null,
              organizer: null,
              isSchoolWide: false,
              isClass: false,
              isGoogleEvent: true,
              isAddedEvent: true,
              userInvite: null,
            })
          }else{  //less than 3 conflicts
            conflicts[k]?.conflicts.map((cs: any, i: any) => {
              let event = (allEvents?.filter((ev:any)=>cs.id === ev.eventId))[0];
              summary.push({
                ... event,
                start: new Date(moment(event.start).set("minute", 0).set("second", 1).toISOString()),
                end: new Date(moment(event.start).add(1, "hour").set("minute", 0).subtract(1,"second").toISOString()),
                actualStart: event.start,
                actualEnd: event.end,
              });
            });
          }
        }
        continue;
      }  
      let startTimes = conflicts[k].date;
      let slotSummary = {
        title: conflicts[k].eventTitle,
        conflicts: eventIDs,
        eventId: null,
        allDay: false,
        start: new Date(moment(startTimes).set("minute", 0).set("second", conflicts[k].eventIsAdded?1:conflicts[k].eventIsGoogleEvent?2:0).toISOString()),
        end: new Date(moment(startTimes).add(1, "hour").set("minute", 0).subtract(1,"second").toISOString()),
        description: "conflict summary for slot",
        location: null,
        memberGroup: null,
        organizer: null,
        isSchoolWide: false,
        isClass: false,
        isGoogleEvent: conflicts[k].eventIsGoogleEvent,
        isAddedEvent: conflicts[k].eventIsAdded,
        userInvite: null,
        neighborCount: conflicts[k].neighborCount??0,
      };
      summary.push(slotSummary);
    }
    for (let j = 0; j < xconflicts.length; j++) {
      let event = allEvents.filter((a:any)=>a.eventId === xconflicts[j].eventId);
      if ((moment(event[0].start).date() !== (moment(event[0].end).date()))) {  // multiday events
        continue;
      }
      summary.push({
        ...event[0],
        start: new Date(moment(event[0].start).set("minute", 0).set("second", event[0].isAddedEvent?1:event[0].isGoogleEvent?2:0).toISOString()),
        end: new Date(moment(event[0].start).add(1, "hour").set("minute", 0).subtract(1,"second").toISOString()),
        actualStart: event[0].start,
        actualEnd: event[0].end,
      })
    }

    // added and google events go last for calendar rendering positioning
    for (let i = 0; i < allEvents.length; i++) {
      if (noConflicts?.includes(allEvents[i].eventId)) {
        // no conflicts
        if (allEvents[i].location === "breeze.school.event.preferred") {    
          //fix count by ignoring redundant event
          if((allEvents.filter((a:any)=>a.title === allEvents[i].title && a.eventId !== allEvents[i].eventId)).length > 0){
            continue;
          }
        }

        if ((moment(allEvents[i].start).date() !== (moment(allEvents[i].end).date()))) {  // multiday events
          if(allConflictIds.includes(allEvents[i].eventId)) continue;
          if(((moment(allEvents[i].start).date()+1 < (moment(allEvents[i].end).date())))){//more than 1 day
            summary.push(allEvents[i]);
            allDayEvents.push(allEvents[i]);
          }else if((moment(allEvents[i].start).date()+1 === (moment(allEvents[i].end).date()))){//overnight
            let daysCount = (moment(allEvents[i]?.end).date()) - (moment(allEvents[i]?.start).date());
            if(daysCount>=1 && (((moment(allEvents[i].end).hour() === 3) && (moment(allEvents[i].end).minute() >= 1)) || (moment(allEvents[i].end).hour() >= 3))){  //event reaches past 3:01am
              summary.push(allEvents[i]);
              allDayEvents.push(allEvents[i]);
            }else if(((moment(allEvents[i].end).hour() === 3) && (moment(allEvents[i].end).minute() < 1))||(moment(allEvents[i].end).hour() < 3)){  //event ends at or before 3am
              summary.push({
                ...allEvents[i],
                start: new Date(moment(allEvents[i].start).set("minute", 0).set("second", allEvents[i].isAddedEvent?1:allEvents[i].isGoogleEvent?2:0).toISOString()),
                end: new Date(moment(allEvents[i].start).set("minute", 59).set("second",59).toISOString()),
                actualStart: allEvents[i].start,
                actualEnd: allEvents[i].end,
              })
            }
          }else{
            summary.push({
              ...allEvents[i],
              start: new Date(moment(allEvents[i].start).set("minute", 0).set("second", allEvents[i]?.isAddedEvent?1:allEvents[i]?.isGoogleEvent?2:0).toISOString()),
              end: new Date(moment(allEvents[i].start).set("minute", 59).set("second",59).toISOString()),
              actualStart: allEvents[i].start,
              actualEnd: allEvents[i].end,
            })
          }
        }
      }
    }

    setEventItems(summary);
    setAllDayEvents(allDayEvents);
    CountAllDay(allDayEvents);
  };

  const formats = useMemo(() => ({
    eventTimeRangeFormat: () => {
      //removes time in event container
      return "";
    },
    dateFormat: (date: any, culture: any, localizer: any) =>
      localizer.format(date, "D", culture),
    dayFormat: (date: any, culture: any, localizer: any) =>
      localizer.format(date, "ddd D", culture),
    dayHeaderFormat: (date: any, culture: any, localizer: any) =>
      localizer.format(date, 'dddd MMMM Do', culture),
    dayRangeHeaderFormat: ({ start, end }: { start: any, end: any }, culture: any, localizer: any) =>
      localizer.format(start, "MMMM YYYY", culture),
    timeGutterFormat: (date: any, culture: any, localizer: any) =>
      localizer.format(date, "h \n a", culture),
  }), [])

  useEffect(() => {
    let overlaps: any[] = [];
    let neighborCount = 0;
    let conflictsWith = [];
    let addedConflicts = [];
    let googleConflicts = [];
    let conflictIds = [];
    let events = [
      ...eventsFuture.map((e: any) => {
        if (e.UserMembership) {
          e.MembershipType = e.UserMembership.MemberType;
          e.MembershipTypeName =
            e.UserMembership.MemberType == 1 ? "Member" : "Moderator";
        }

        e.isGoogleEvent =
          googleEvents && googleEvents.length > 0
            ? googleEvents.some(
              (f: any) =>
                f.title === e.title &&
                f.location === "breeze.school.event.preferred" &&
                moment(f.startDate).isSame(moment(e.startDate))
            )
            : false;

        return e;
      }),
      ...googleEvents,
    ];
    events.sort(function(a:any,b:any){    //sort by startDate
      return (Date.parse(a.startDate) - Date.parse(b.startDate));
    });
    let trueHead = 0;
    let headPushed = false;
    for (let head = 0; head < events.length; head++) {
      if ((moment(events[trueHead].startDate).hour() === (moment(events[head].startDate).hour()))) { //next head is same hour then continue
        continue;
      }
      trueHead = head;
      if(((moment(events[head].startDate).date() !== (moment(events[head].endDate).date()))) && (moment(events[head].startDate).date()+1 === (moment(events[head].endDate).date())) && ((moment(events[head].endDate).hour() > 3) || ((moment(events[head].endDate).hour() === 3) && (moment(events[head].endDate).minute() >= 1)))){
        //allday push separately
        overlaps.push({
          day: moment(events[head].startDate).format("D"),
          date: events[head].startDate,
          endDate: events[head].endDate,
          allDay: true,
          eventId: events[head].eventId,
          eventTitle: events[head].title,
          eventInterest: events[head].interest,
          eventIsGoogleEvent: events[head].isGoogleEvent,
          eventIsAdded:(events[head].isGoogleEvent && events[head].schoolId !== undefined),
          eventLocation: events[head].location,
          eventIsHidden: events[head].isGoogleEvent
            && events[head].location === 'breeze.school.event.preferred'
            && eventsFuture.some((e: any) => e.title === events[head].title)
            ? true
            : false,
          conflicts: null,
          mutual: events[head].mutual ?? null,
          neighborCount:neighborCount,
        })
        continue;
      }
      for (let i = head+1; i < events.length; i++) {
        if(i===head) continue;

        if ((moment(events[i].startDate).hour() === (moment(events[head].startDate).hour()))) { //same hour as head
          if(((moment(events[i].startDate).date() !== (moment(events[i].endDate).date()))) && (moment(events[i].startDate).date()+1 === (moment(events[i].endDate).date())) && ((moment(events[i].endDate).hour() > 3) || ((moment(events[i].endDate).hour() === 3) && (moment(events[i].endDate).minute() >= 1)))){
            // allday push separately
            overlaps.push({
              day: moment(events[i].startDate).format("D"),
              date: events[i].startDate,
              endDate: events[i].endDate,
              allDay: true,
              eventId: events[i].eventId,
              eventTitle: events[i].title,
              eventInterest: events[i].interest,
              eventIsGoogleEvent: events[i].isGoogleEvent,
              eventIsAdded:(events[i].isGoogleEvent && events[i].schoolId !== undefined),
              eventLocation: events[i].location,
              eventIsHidden: events[i].isGoogleEvent
                && events[i].location === 'breeze.school.event.preferred'
                && eventsFuture.some((e: any) => e.title === events[i].title)
                ? true
                : false,
              conflicts: null,
              mutual: events[i].mutual ?? null,
              neighborCount:neighborCount,
            })
            continue;
          }
          // console.log(i," ",events[i])
          if (
            !(events[i].isGoogleEvent)
            ) {
            conflictIds.push(events[i].eventId);
            conflictsWith.push({
              name: events[i].title,
              id: events[i].eventId,
              interest: events[i].interest,
              isGoogleEvent: events[i].isGoogleEvent,
              location: events[i].location,
              eventIsHidden:
                events[i].isGoogleEvent &&
                  events[i].location === "breeze.school.event.preferred" &&
                  eventsFuture.some((e: any) => e.title === events[i].title)
                  ? true
                  : false,
              mutual: events[i].mutual ?? null,
            });
          }else{
            //push google events and added events separately
            if(events[i].isGoogleEvent && events[i].schoolId !== undefined){//added events
              //skip multiday events
              neighborCount++;
              conflictIds.push(events[i].eventId);
              addedConflicts.push({
                name: events[i].title,
                id: events[i].eventId,
                interest: events[i].interest,
                isGoogleEvent: events[i].isGoogleEvent,
                location: events[i].location,
                eventIsHidden:
                  events[i].isGoogleEvent &&
                    events[i].location === "breeze.school.event.preferred" &&
                    eventsFuture.some((e: any) => e.title === events[i].title)
                    ? true
                    : false,
                mutual: events[i].mutual ?? null,
              });
            }else{  //google events
              if(events[i].location!=="breeze.school.event.preferred"){
                neighborCount++;
                conflictIds.push(events[i].eventId);
                  googleConflicts.push({
                    name: events[i].title,
                    id: events[i].eventId,
                    interest: events[i].interest,
                    isGoogleEvent: events[i].isGoogleEvent,
                    location: events[i].location,
                    eventIsHidden:
                      events[i].isGoogleEvent &&
                        events[i].location === "breeze.school.event.preferred" &&
                        eventsFuture.some((e: any) => e.title === events[i].title)
                        ? true
                        : false,
                    mutual: events[i].mutual ?? null,
                  });
              }
            }
          }
        }else{
          head = i-1;
          break;
        }
      }

        if(conflictsWith.length>0){
          overlaps.push({
            day: moment(events[trueHead].startDate).format("D"),
            date: events[trueHead].startDate,
            endDate: events[trueHead].endDate,
            eventId: events[trueHead].isGoogleEvent===false?events[trueHead].eventId:conflictsWith[0]?.id,
            eventTitle: events[trueHead].isGoogleEvent===false?events[trueHead].title:conflictsWith[0]?.name,
            eventInterest: events[trueHead].isGoogleEvent===false?events[trueHead].interest:conflictsWith[0]?.interest,
            eventIsGoogleEvent: events[trueHead].isGoogleEvent===false?false:conflictsWith[0]?.isGoogleEvent,
            eventIsAdded:events[trueHead].isGoogleEvent===false?events[trueHead].false:(events[0]?.isGoogleEvent && events[0]?.schoolId !== undefined),
            eventLocation: events[trueHead].isGoogleEvent===false?events[trueHead].location:conflictsWith[0]?.location,
            eventIsHidden: false,
            conflicts: (events[trueHead].isGoogleEvent===false) ? conflictsWith : conflictsWith.length > 1?conflictsWith.slice(1):null,
            mutual: events[trueHead].mutual ?? null,
            neighborCount:neighborCount,
          })
          if(events[trueHead].isGoogleEvent===false){
            headPushed = true;
          }
        }
        if(addedConflicts.length>0){
          let isAddedHead = (events[trueHead].isGoogleEvent===true&& events[trueHead].schoolId !== undefined);
          overlaps.push({
            day: moment(events[trueHead].startDate).format("D"),
            date: events[trueHead].startDate,
            endDate: events[trueHead].endDate,
            eventId: isAddedHead?events[trueHead].eventId:addedConflicts[0]?.id,
            eventTitle: isAddedHead?events[trueHead].title:addedConflicts[0]?.name,
            eventInterest: isAddedHead?events[trueHead].interest:addedConflicts[0]?.interest,
            eventIsGoogleEvent: true,
            eventIsAdded:true,
            eventLocation: isAddedHead?events[trueHead].location:addedConflicts[0]?.location,
            eventIsHidden: isAddedHead?events[trueHead].isGoogleEvent:addedConflicts[0]?.isGoogleEvent
              && isAddedHead?events[trueHead].location:addedConflicts[0]?.location === 'breeze.school.event.preferred'
              ? true
              : false, 
            conflicts: (isAddedHead) ? addedConflicts : addedConflicts.length > 1?addedConflicts.slice(1):null,
            mutual: null,
            neighborCount:neighborCount,
          })
          if(isAddedHead){
            headPushed = true;
          }
        }
        if(googleConflicts.length>0){
          let isGoogleHead = (events[trueHead].isGoogleEvent===true&& events[trueHead].schoolId === undefined);
          overlaps.push({
            day: moment(events[trueHead].startDate).format("D"),
            date: events[trueHead].startDate,
            endDate: events[trueHead].endDate,
            eventId: isGoogleHead?events[trueHead].eventId:googleConflicts[0]?.id,
            eventTitle: isGoogleHead?events[trueHead].title:googleConflicts[0]?.name,
            eventInterest: isGoogleHead?events[trueHead].interest:googleConflicts[0]?.interest,
            eventIsGoogleEvent: true,
            eventIsAdded:false,
            eventLocation: isGoogleHead?events[trueHead].eventId:googleConflicts[0]?.location,
            eventIsHidden: isGoogleHead?events[trueHead].isGoogleEvent:googleConflicts[0]?.isGoogleEvent
              && isGoogleHead?events[trueHead].location:googleConflicts[0]?.location === 'breeze.school.event.preferred'
              ? true
              : false,
            conflicts: (isGoogleHead) ? googleConflicts : googleConflicts.length > 1?googleConflicts.slice(1):null,
            mutual: null,
            neighborCount:neighborCount,
          })
          if(isGoogleHead){
            headPushed = true;
          }
        }
        if(headPushed===false){
          overlaps.push({
            day: moment(events[trueHead].startDate).format("D"),
            date: events[trueHead].startDate,
            endDate: events[trueHead].endDate,
            eventId: events[trueHead].eventId,
            eventTitle: events[trueHead].title,
            eventInterest: events[trueHead].interest,
            eventIsGoogleEvent: events[trueHead].isGoogleEvent,
            eventIsAdded:events[trueHead].isGoogleEvent && events[trueHead].schoolId!==undefined,
            eventLocation: events[trueHead].eventId,
            eventIsHidden: events[trueHead].isGoogleEvent
              && events[trueHead].location === 'breeze.school.event.preferred'
              ? true
              : false,
            conflicts: null,
            mutual: null,
            neighborCount:neighborCount,
          })
        }
      // trueHead = head;
      headPushed = false;
      setAllConflictIds(conflictIds);
      neighborCount = 0;
      conflictsWith = [];
      addedConflicts = [];
      googleConflicts= [];
    }
    //NOTE: to retun
    sortByInterest(overlaps);
    setOverlappingEvents(overlaps);
    let data = events.map((data) => {
      return {
        title: data.title,
        eventId: data.eventId,
        allDay: false,
        start: new Date(data.startDate),
        end: new Date(data.endDate),
        description: data.description,
        location: data.location,
        memberGroup: data.memberGroup,
        organizer: data.eventCreatedByName,
        isSchoolWide: data.isSchoolWideEvent,
        isGoogleEvent: data.isGoogleEvent ?? false,
        isClass: data.eventType === 3 ? true : false,
        userInvite: data.userInvite,
        slug: data?.slug,
        eventTagIds: data.eventTagIds ?? null,
        metadata: data.metadata ?? null,
        interest: { name: data.interest ?? null, id: data.interestId ?? null },
        mutual: data.mutual ?? null,
        isAddedEvent: (data.isGoogleEvent && data.schoolId !== undefined),
      };
    });
    setAllEventItems(data);
    if (calView === "month" && data?.length > 0) {
      GetMonthSummary(data);
    }else{
      GetWeekDaySummary(data, overlaps);
    }
  }, [eventsFuture, googleEvents]);

  useEffect(() => {
    handleCalendarFilterDateChange(dateFilter, calView);
    CountAllDay(allDayEvents);
    setExpandAllDay(false);
  }, [dateFilter, calView]);

  useEffect(() => {
    if (calView === "month" && allEventItems.length > 0) {
      GetMonthSummary(allEventItems);
    }else {
      GetWeekDaySummary(allEventItems, overlappingEvents);
    }
  }, [calView, selectedTime]);

  useEffect(() => {
    const handleResize = () => {
      setWidthSize(window.innerWidth);
      if(window.innerWidth<992){
        setCalView(window.innerWidth<992 && calView === "week" ? "day" : calView);
        setView(window.innerWidth<992 && calView === Views.WEEK ? Views.DAY : view);
        setFromMobile(true);
      } else if(fromMobile) {
        setCalView(calView === "day" ? "week" : calView);
        setView(calView === Views.DAY ? Views.WEEK : view);
        setFromMobile(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [calView, view,fromMobile]);

  return (
    <Fragment>
      <ModalEventView
        selectedTime={selectedTime}
        eventData={eventData}
        allEvents={allEventItems}
        eventTags={eventTags}
        isVisible={isVisible}
        onEventModify={() => {
          setIsVisible(false);
          refresh();
        }}
        setIsVisible={setIsVisible}
        conflicts={
          overlappingEvents?.length > 0 &&
          (overlappingEvents?.find((o: any) => o?.eventId === eventData?.eventId) ??
            null)
        }
        onModalCancelHandler={() => setIsVisible(false)}
        // saveItemHandler={() => alert("save event")}
        refresh={refresh}
      />
      <ModalEventList
        selectedTime={selectedTime}
        eventData={eventData}
        eventTags={eventTags}
        allEvents={allEventItems}
        isVisible={eventListIsVisible}
        displayEventData={displayEventData}
        setIsVisible={setEventListIsVisible}
        onModalCancelHandler={() => setEventListIsVisible(false)}
        // saveItemHandler={() => alert("save event")}
        refresh={refresh}
        onRemoveEvent={(eventId: any) => {
          refresh();
        }}
      />
      {calView !== "month" && <WeekDaySelector dateRange={dateFilter} setDate={setDateFilter} /> }
      <Calendar
        className={`${calView==='day'?'mobile-week':''} ${expandAllDay===true?"expand":''} ${(allDayCount?.total<=0 || member?.displayAllDayEvents===false)?"allday-none":''} ${(calView==='day' && allDayCount?.total===1)?"allday-one":''}`}
        dayLayoutAlgorithm={"no-overlap"}
        views={["month", "week", "day"]}
        view={view}
        onNavigate={onNavigate}
        date={dateFilter}
        defaultDate={moment().toISOString()}
        defaultView={"day"}
        // defaultView={widthSize<992?"month":"week"}
        events={eventItems}
        localizer={mLocalizer}
        eventPropGetter={eventPropGetterDefault}
        slotPropGetter={slotPropGetter}
        onSelectEvent={(event) => displayEventData(event)}
        showAllEvents={true}
        // onSelectSlot={(view)=>console.log(view)}
        onDrillDown={(...props)=>{
          setDateFilter(moment(props[0], "YYYY MM DD").toISOString());
          setCalView(widthSize<992?"week":"day");
          setView(Views.DAY);
        }}
        selectable
        formats={formats}
        scrollToTime={new Date(0, 0, 0, 5, 0, 0)}
        components={{
          timeGutterHeader: () => <TimeGutterHeader expand={expandAllDay} setExpandAllDay={setExpandAllDay} count={allDayCount} view={calView}/>,
          toolbar: (props) => <CalendarToolBar {...props} isLoading={isLoading} updateProfile={updateProfile}
                setDateFilter={setDateFilter} dateFilter={dateFilter} setCalView={setCalView} setView={setView} widthSize={widthSize} member={member} />, // Pass state as prop
          // dateCellWrapper: DateCell,   //conflict exclamation on month
          day: {
            event: ({ event, children }) => (
              <EventComponentDay event={event} children={children} refresh={refresh} />
            ),
            header: ({ date, localizer }) => (
              <DayHeader date={date} localizer={localizer} />
            ),
          },
          week: {
            header: ({ date, localizer }) => (
              <WeekHeader date={date} localizer={localizer} />
            ),
          },
          month: {
            event: EventComponentMonth,
          },
          event: (props) => <EventComponent {...props} refresh={refresh}/>,
          eventWrapper: ({ event, children }) => <CustomEventWrapper event={event} calView={calView} allEventItems={allEventItems} refresh={refresh}>{children}</CustomEventWrapper>
        }}
        tooltipAccessor={null}
      />
      <div className="calendar-view-legends pb-3">
        <div className="legend">
          <div className="light-blue-circle" />
          <p className="mb-0">
            Breeze Events
          </p>
        </div>
        <div className="legend">
          <div className="blue-circle" />
          <p className="mb-0">
            Added
          </p>
        </div>
        <div className="legend">
          <div className="grey-circle" />
          <p className="mb-0">
            Calendar
          </p>
        </div>
        {/* <p className="legend">
          <div className="white-circle">
            <img src={BookOpen}/>
          </div>
          <span>
            Class
          </span>
        </p> */}
      </div>
    </Fragment>
  );
};

export default memo(BreezeCalendar);