import {
  Fragment,
  useState,
  useEffect,
  useRef,
  forwardRef,
  ForwardedRef,
} from "react";
import {
  Button,
  Tooltip,
  message,
  Dropdown,
  Spin,
  Menu,
} from "antd";
import moment from "moment";
import {
  LoadingOutlined,
  DownloadOutlined,
  DownOutlined,
  PlusOutlined,
  MinusOutlined,
  UserOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import Ticket from "../../assets/images/icons/ticket.svg";
import Share from "../../assets/images/icons/share-b.svg";
import { contactsColors } from "../../helpers/contactsColors";

const EventItem = forwardRef(
  (props: any, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      data,
      selectedTime,
      allEvents,
      onModalCancelHandler,
      setContacts,
      setIsContactsModalOpen,
      googleAccessToken,
      createCalendarEvent,
      refresh,
      eventList,
      deleteCalendarEvent,
      displayData,
      setList,
    } = props;
    let event: any = data;
    const [eventLoadingId, setEventLoadingId] = useState("");
    const [widthSize, setWidthSize] = useState<number>(window.innerWidth);
    let isPrimetime = false;
    let isUpcomingEvent = moment(
      data?.actualStart ?? data?.start
    ).isAfter(new Date());
    
    const times = [
      { name: "Morning", start: "05:00:00", end: "11:59:59" },
      { name: "Afternoon", start: "12:00:00", end: "17:59:59" },
      { name: "Night", start: "18:00:00", end: "23:59:59" },
    ];
    const timeSlot = times.filter((t) => t.name == selectedTime)[0];
    if (timeSlot != undefined) {
      isPrimetime = moment(event?.start).isBetween(
        moment(timeSlot.start, "HH:mm:ss"),
        moment(timeSlot.end, "HH:mm:ss")
      );
    }

    const club = event?.metadata?.fields.find(
      (f: any) => f.name === "clubLogin"
    ).value;
    const eventUrl = event?.metadata?.fields.find(
      (f: any) => f.name === "eventUrl"
    )?.value;
    const location = event?.metadata?.fields.find(
      (f: any) => f.name === "eventLocation"
    )?.value;
    const bannerUrl = event?.metadata?.fields.find(
      (f: any) => f.name === "eventPicture"
    )?.value;
    const eventPic =
      bannerUrl && bannerUrl.startsWith("http")
        ? bannerUrl
        : bannerUrl
        ? `https://yaleconnect.yale.edu${
            event?.metadata?.fields.find((f: any) => f.name === "eventPicture")
              ?.value
          }`
        : null;
    const hasGoogleEvent =
      event?.hasGoogleEvent ||
      !!allEvents?.find(
        (e: any) =>
          e.location === "breeze.school.event.preferred" &&
          event.title === e.title
      );

    const onViewContacts = (contacts: any) => {
      onModalCancelHandler();
      setContacts(
        contacts.map((e: any) => ({
          name: e.displayName,
          image: e.profilePicture,
        }))
      );
      setIsContactsModalOpen(true);
    };

    const onReadMore = () => {
      onModalCancelHandler();
      displayData(event);
    };

    const shareEvent = () =>{
      let url = `${(window.location.origin)}/event/${event?.slug}`
      navigator.clipboard.writeText(url)
      message.success("Event Link Copied to Clipboard!");
    }

    return (
      <div className="list-event-item" ref={ref} data-eventId={event.eventId}>
        <p className="time">
          {moment(event?.start).format("D MMMM YYYY h:mm a")} -{" "}
          {moment(event?.start).isSame(event?.end, "day")
            ? moment(event?.end).format("h:mm a")
            : moment(event?.end).format("MMM D, h:mm a")}
        </p>
        <div className="main-data">
          <UserOutlined className="img-placeholder"/>
          <div className="data">
            <Tooltip title={event?.title}>
              <h2 className="title">{event?.title}</h2>
              <p className="organizer">{event?.organizer ?? 'N/A'}</p>
            </Tooltip>
          </div>
        </div>
        {eventPic ? (
          <img className="image-banner" src={eventPic} />
        ) : (
          <div className="image-placeholder" />
        )}
        <div className="content">
          <span className="info-point">
            <Tooltip
              title={
                location ? location.trim() ?? "No Location" : "No Location"
              }
            >
              <p>
                {location ? `at ${location.trim()}` ?? "No Location" : "No Location"}
              </p>
            </Tooltip>
          </span>
          <Tooltip title={event?.description}>
            <p className="grey-text description">{event?.description}</p>
          </Tooltip>
          <Button type="text" className="read-more" onClick={onReadMore}>Read more +</Button>
          <div className="button-group">
            {(event.isGoogleEvent===false||event.isAddedEvent===true) && event.metadata !== null && (
              <div className="img-btns">
                <Button className="circle-btn" disabled><img src={Ticket}/></Button>
                <Button className="circle-btn" onClick={shareEvent}><img src={Share}/></Button>
              </div>
            )}
            {event?.metadata !== null &&
            isUpcomingEvent && (
          <Dropdown
              placement={widthSize<992?"topLeft":"bottomLeft"}
              className={event?.isGoogleEvent?"added-btn":"add-btn"}
              overlay={
                  <Menu>
                    {!event?.isGoogleEvent ? (
                      <>
                        <Menu.Item
                            key='add'
                            onClick={async () => {
                              try {
                                setEventLoadingId(event.eventId);
                                const res = await createCalendarEvent({
                                  accessToken: googleAccessToken,
                                  eventId: event.eventId,
                                });
      
                                if (res.status !== 200) throw "Error creating event.";
      
                                const data = res.data;
      
                                const tmpEvents: any = [...eventList];
                                tmpEvents[
                                  eventList
                                    .map((e: any) => e.eventId)
                                    .indexOf(event.eventId)
                                ].hasGoogleEvent = true;
      
                                tmpEvents.push({
                                  eventId: data.eventId,
                                  description: data.description,
                                  end: moment(data.endDate),
                                  start: moment(data.startDate),
                                  isGoogleEvent: data.isGoogleEvent,
                                  location: data.location,
                                  title: data.title,
                                  hidden: true,
                                });
      
                                setList(tmpEvents);
                                message.success(
                                  `You successfully added ${event.title}!`
                                );
                                onModalCancelHandler();
                              } catch (e) {
                                message.error("An Error Occured!");
                              }
                              setEventLoadingId("");
                              refresh();
                            }}
                        >
                          <PlusOutlined/> Add to Google Calendar
                        </Menu.Item>
                        <Menu.Item
                            key='download'
                            disabled
                        >
                          <DownloadOutlined/> Download ICS File
                        </Menu.Item>
                      </>)
                      :(
                        <Menu.Item
                            key='Remove'
                            onClick={async () => {
                              try {
                                setEventLoadingId(event.eventId);
            
                                const fromAllEvents = allEvents.find(
                                  (e: any) =>
                                    e.location === "breeze.school.event.preferred" &&
                                    event.title === e.title
                                ) as any;
                                const fromEventList = eventList.find(
                                  (e: any) =>
                                    e.location === "breeze.school.event.preferred" &&
                                    event.title === e.title
                                ) as any;
            
                                const googleEventCounterpart =
                                  fromAllEvents ?? fromEventList;
            
                                const res = await deleteCalendarEvent({
                                  accessToken: googleAccessToken,
                                  googleEventId: hasGoogleEvent
                                    ? googleEventCounterpart?.eventId
                                    : event.eventId,
                                  eventId: hasGoogleEvent ? event.eventId : "",
                                });
            
                                if (res.status !== 200) throw "Error deleting event.";
            
                                if (hasGoogleEvent) {
                                  const tmpEvents: any = [...eventList];
                                  tmpEvents[
                                    eventList
                                      .map((e: any) => e.eventId)
                                      .indexOf(event.eventId)
                                  ].hasGoogleEvent = false;
                                  setList(
                                    tmpEvents.filter(
                                      (e: any) =>
                                        !(
                                          e.location === "breeze.school.event.preferred" &&
                                          event.title === e.title
                                        )
                                    )
                                  );
                                  onModalCancelHandler();
                                } else {
                                  setList(
                                    eventList.filter(
                                      (e: any) => e.eventId !== event.eventId
                                    )
                                  );
                                }
                                message.success(`You successfully removed ${event.title}!`);
                              } catch (e) {
                                message.error("An Error Occured!");
                              }
                              setEventLoadingId("");
            
                              refresh();
                            }}
                        >
                          <MinusOutlined/> Remove from Calendar
                        </Menu.Item>
                      )
                    }
                  </Menu>
              }
          >
              <Button>
                  {event?.isGoogleEvent?"Added":"Add to Calendar"} {event.eventId === eventLoadingId?<Spin indicator={<LoadingOutlined spin />} className="loading-spin"/>: event?.isGoogleEvent?<CheckOutlined/>:<DownOutlined />}
              </Button>
          </Dropdown>)}
          </div>
        </div>
      </div>
    );
  }
);
export default EventItem;
