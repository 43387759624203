import {
  Fragment,
  useState,
  useEffect,
  useRef,
  forwardRef,
  ForwardedRef,
  useLayoutEffect,
} from "react";
import {
  Button,
  Modal,
  Tooltip,
  message,
  Form,
  Spin,
  Tag,
  Space,
  Avatar,
} from "antd";
import moment from "moment";
import { connect } from "react-redux";
import {
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {
  acceptEventInvite,
  declineEventInvite,
  deleteEvent,
} from "../../actions/event";
import { OK, NO_CONTENT } from "../../helpers/statuscodes";
import Calendar from "../../assets/images/icons/calendar-blue.svg";
import MapPin from "../../assets/images/icons/map-pin-blue.svg";
import Target from "../../assets/images/icons/target-blue.svg";
import Category from "../../assets/images/icons/category.svg";
import Contacts from "../../assets/images/icons/user-check-b.svg";
import ModalContacts from "./ModalContacts";
import { contactsColors } from "../../helpers/contactsColors";
import {
  createCalendarEvent,
  deleteCalendarEvent,
} from "../../actions/calendar";
import React from "react";
import EventItem from "./EventItem";
import { createEventViewTrail } from "../../actions/analytics";

const ModalEventList = (props: {
  acceptEventInvite: Function;
  declineEventInvite: Function;
  deleteEvent: Function;
  refresh: Function;
  eventData: any;
  eventTags: any;
  allEvents: any;
  isVisible: boolean;
  setIsVisible: Function;
  onModalCancelHandler: any;
  selectedTime: any;
  googleAccessToken: string;
  createCalendarEvent: Function;
  deleteCalendarEvent: Function;
  displayEventData: Function;
  onRemoveEvent: Function;
  createEventViewTrail: Function;
}) => {
  const {
    acceptEventInvite,
    declineEventInvite,
    eventData,
    eventTags,
    allEvents,
    isVisible,
    setIsVisible,
    deleteEvent,
    onModalCancelHandler,
    refresh,
    selectedTime,
    googleAccessToken,
    createCalendarEvent,
    deleteCalendarEvent,
    onRemoveEvent,
    displayEventData,
    createEventViewTrail
  } = props;
  const [hasInvite, setHasInvite] = useState(false);
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [isDeclineEventModalOpen, setIsDeclineEventModalOpen] = useState(false);
  const [isContactsModalOpen, setIsContactsModalOpen] = useState(false);
  const [isLoadingDeclineEventInvite, setIsLoadingDeclineEventInvite] =
    useState(false);
  const [eventList, setList] = useState([]);
  const [declineEventForm] = Form.useForm();
  const [widthSize, setWidthSize] = useState<number>(window.innerWidth);

  const [contacts, setContacts] = useState([]);
  const sampleContacts = [
    {
      name: "John Smith",
      image: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    },
    { name: "K" },
    { name: "Steven Bills" },
    { name: "Hugh Man" },
    {
      name: "Ant User",
      image:
        "https://i.guim.co.uk/img/static/sys-images/Guardian/Pix/pictures/2015/7/9/1436452920505/e50aaf50-51a1-46b3-a238-77f8cef2e053-2060x1236.jpeg?width=620&quality=85&auto=format&fit=max&s=fd8c00c346c3dfc33e8cfa6d06b43380",
    },
    {
      name: "Jiggly Puff",
      image: "https://pokestop.io/img/pokemon/jigglypuff-256x256.png",
    },
  ];

  useEffect(() => {
    if (isVisible === true) {
      let list = [];
      // recommended events
      if (Array.isArray(eventData) && eventData.length > 0) {
        list = eventData;
      }

      if (allEvents.length > 0) {
        if (eventData?.conflicts !== undefined) {
          list = eventData?.conflicts?.map(
            (c: any) => allEvents.filter((a: any) => a.eventId === c)[0]
          );
        } else if (eventData?.classes !== undefined) {
          list = eventData?.classes?.map(
            (c: any) => allEvents.filter((a: any) => a.eventId === c)[0]
          );
        }
      }
      setList(list);
    }
  }, [isVisible]);

  const onBack = () => {
    setIsVisible(true);
    setContacts([]);
    setIsContactsModalOpen(false);
  };

  const scrollingDivRef = useRef<any>(null);
  const [dataRef, setDataRef] = useState<any>([]);

  const eventFiltered =
    eventList &&
    eventList?.filter(
      (e: any) => !e.hidden && e.location !== "breeze.school.event.preferred"
    );
  useEffect(() => {
    let elref = eventFiltered.map(() => React.createRef());
    setDataRef(elref);
  }, [eventList,eventFiltered.length]);

  const isElementInViewport = (container:any, element:any) => {
    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    const isHorizontallyInView = 
        elementRect.left >= containerRect.left && 
        elementRect.right <= containerRect.right;

    const isVerticallyInView = 
        elementRect.top >= containerRect.top && 
        elementRect.bottom <= containerRect.bottom;

    return isHorizontallyInView && isVerticallyInView;
};

useLayoutEffect(() => {
    const scrollingDiv = scrollingDivRef.current;

    let scrollTimeout :any;

    const handleScroll = () => {
        dataRef.forEach(async (ref: any, index: any) => {
          if (ref.current && scrollingDivRef.current) {
            // console.log(
            //   `Element ${index + 1} in viewport:`,
            //   isElementInViewport(
            //     scrollingDivRef.current,
            //     ref.current
            //   )
            // );
            if(isElementInViewport(
              scrollingDivRef.current,
              ref.current
            )){
              await createEventViewTrail(ref.current.dataset.eventid);
            }
          }
        });
    };

    const debouncedHandleScroll = () => {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
            handleScroll();
        }, 200); // Adjust the debounce delay as needed
    };

    if (scrollingDiv) {
        scrollingDiv.addEventListener("scroll", debouncedHandleScroll);

        setTimeout(() => {
          handleScroll();
      }, 500);

        return () => {
            scrollingDiv.removeEventListener("scroll", debouncedHandleScroll);
        };
    }
  }, [eventList,eventFiltered.length,dataRef]);

  useEffect(() => {
    setWidthSize(window.innerWidth);
  }, [isVisible]);

  return (
    <>
      <ModalContacts
        visible={isContactsModalOpen}
        onCancel={() => setIsContactsModalOpen(false)}
        onBack={onBack}
        // contacts={eventData?.mutual ? [] : eventData?.mutual.map((e:any) => {
        //   return {
        //     name: e.displayName ?? ''
        //   }
        // })}
        contacts={contacts}
      />
      <Modal
        title={
        <>
          {widthSize<992 && (
            <Button
                className="mr-1"
                style={{border:'none', backgroundColor:'transparent'}}
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={onModalCancelHandler}
            />
        )}
          {eventList.length > 0
            ? window.innerWidth < 992
              ? ` ${moment(eventList[0]["start"]).format("h A")} ${
                  eventList[0]["isAddedEvent"]
                    ? "Added"
                    : eventList[0]["isGoogleEvent"]
                    ? "Google"
                    : "Breeze"
                } Events -  ${moment(eventList[0]["start"]).format(
                  "MMMM DD, YYYY"
                )}`
              : "Event List"
            : "Event List"}
        </>
        }
        closable={widthSize>992?true:false}
        className="modal-event-list"
        centered
        visible={isVisible}
        onCancel={() => onModalCancelHandler()}
        footer={null}
      >
        <div className="d-flex">
          <div className="d-flex-column w-100">
            <div className="event-list" id="scrollingDiv" ref={scrollingDivRef}>
              {
                eventList &&
                  // Load all event due to removal of see more button
                  eventList
                    ?.filter(
                      (e: any) =>
                        !e.hidden &&
                        e.location !== "breeze.school.event.preferred"
                    )
                    .map((c: any, index: any) => (
                      <EventItem
                        data={c}
                        key={c.eventId}
                        ref={dataRef[index]}
                        eventList={eventList}
                        setList={setList}
                        displayData={displayEventData}
                        {...props}
                      />
                    ))
                // eventList?.slice(0,6).map((c:any)=><EventItem data={c} key={c.eventId}/>)
              }
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: { auth: any; googleAccessToken: string }) => ({
  googleAccessToken: state.auth?.googleAccessToken,
});
export default connect(mapStateToProps, {
  acceptEventInvite,
  declineEventInvite,
  deleteEvent,
  createCalendarEvent,
  deleteCalendarEvent,
  createEventViewTrail,
})(ModalEventList);
